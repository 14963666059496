<template>
  <div v-if="space">
    <p class="title">{{ space.Name }}</p>

    <div class="columns">
      <div class="column">
        <img :src="defaultImage | getLocationImage(space.LocationId, '320')" />
      </div>
      <div class="column">
        <table class="table is-fullwidth is-striped is-narrow">
          <tbody>
            <tr>
              <td>Width</td>
              <td class="has-text-right">{{ space.Width.toFixed(2) }} m</td>
            </tr>

            <tr>
              <td>Length</td>
              <td class="has-text-right">{{ space.Length.toFixed(2) }} m</td>
            </tr>

            <tr>
              <td>Height</td>
              <td class="has-text-right">{{ space.Height.toFixed(2) }} m</td>
            </tr>

            <tr>
              <td>Area</td>
              <td class="has-text-right">{{ space.M2 }} m2</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <table class="table is-fullwidth is-striped is-narrow">
      <thead>
        <tr>
          <th>Layout</th>
          <th class="has-text-right">
            Min people
          </th>
          <th class="has-text-right">
            Max people
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="setting in space.Settings" :key="setting.Id">
          <td>{{ setting.SettingId | getSettingName }}</td>
          <td class="has-text-right">{{ setting.MinSeats }}</td>
          <td class="has-text-right">{{ setting.MaxSeats }}</td>
        </tr>
      </tbody>
    </table>

    <div class="has-text-right">
      <a @click="goToSpace" class="button is-primary is-small">Open space</a>
    </div>

    <div>
      <a @click="setShowCreateNote()" class="is-size-7">
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'plus']" />
        </span>
        <span>Create note</span>
      </a>

      <a @click="setShowCreateToDo()" class="is-size-7">
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'plus']" />
        </span>
        <span>Create todo</span>
      </a>
    </div>

    <div v-if="space.Links.length > 0">
      <p class="subtitle">Linked to</p>

      <ul>
        <li v-for="link in space.Links" :key="link.Id">
          {{ link.LinkToName }}
        </li>
      </ul>
    </div>

    <div>
      <p class="subtitle">
        Block rules
      </p>
      <span v-if="!space.BlockRules || space.BlockRules.length === 0">
        No block rules found
      </span>

      <table
        v-if="space.BlockRules && space.BlockRules.length > 0"
        class="table is-fullwidth is-striped is-narrow is-size-7"
      >
        <tbody>
          <tr v-for="(blockrule, index) in space.BlockRules" :key="index">
            <td>
              <span>{{ blockrule.MeetingtypeId }}</span>
            </td>
            <td>
              <span
                >{{
                  blockrule.StartDate
                    | parseIsoDateStringToDate
                    | dateObjectIsoDateString
                }}
                -
                {{
                  blockrule.EndDate
                    | parseIsoDateStringToDate
                    | dateObjectIsoDateString
                }}</span
              >
            </td>
            <td>
              <span
                >{{ blockrule.StartMinutes | minutesToTime }} -
                {{ blockrule.EndMinutes | minutesToTime }}</span
              >
            </td>
            <td>{{ blockrule.Blocks.length }} days</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <a @click="setShowCreateBlockRule()" class="is-size-7">
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'plus']" />
        </span>
        <span>Create block rule</span>
      </a>
    </div>
  </div>
</template>

<script>
import spaceProvider from '@/providers/space'

export default {
  props: {
    meetingtypeId: {
      default: 0,
      type: Number,
    },

    spaceId: {
      default: 0,
      type: Number,
    },

    warnings: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  data() {
    return {
      space: null,
    }
  },

  computed: {
    defaultImage() {
      let image = ''

      if (this.space && this.space.Images.length > 0) {
        let defaultIndex = this.space.Images.findIndex((i) => i.IsDefault)
        if (defaultIndex > -1) {
          image = this.space.Images[defaultIndex].Image
        }
      }

      return image
    },

    sortedWarnings() {
      let warnings = this.warnings

      return warnings
    },
  },

  created() {
    this.getSpaceData()
  },

  beforeDestroy() {
    this.space = null
  },

  methods: {
    getSpaceData() {
      spaceProvider.methods.getSpaceById(this.spaceId).then((response) => {
        this.space = response.data
      })
    },

    getWarningColor(level) {
      let background = 'has-background-white'

      if (level === 'Critical') {
        background = 'has-background-danger'
      }

      if (level === 'High') {
        background = 'has-background-danger-light'
      }

      if (level === 'Medium') {
        background = 'has-background-warning'
      }

      if (level === 'Low') {
        background = 'has-background-warning-light'
      }

      return background
    },

    goToSpace() {
      this.$router.push({
        name: `spaces-detail`,
        params: { spaceId: this.space.Id },
      })
    },
  },
}
</script>
